a {
    color: black;
    text-decoration: none;
}

a:hover {
    color:#0c2442;
    text-decoration:none;
    cursor:pointer;
}
.btn-outline-dark{color:#0c2442;border-color:#0c2442}
.btn-outline-dark:hover{color:#fff;background-color:#0c2442;border-color:#0c2442}
.btn-outline-dark.focus,
.btn-outline-dark:focus{box-shadow:0 0 0 .2rem rgba(0,123,255,.5)}
.btn-outline-dark.disadarkbled,
.btn-outline-dark:disabled{color:#0c2442;background-color:transparent}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,.show>
.btn-outline-dark.dropdown-toggle{color:#fff;background-color:#0c2442;border-color:#0c2442}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,.show>
.btn-outline-dark.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(0,123,255,.5)}


.btn-primary{color:white;border-color:#0c2442}
.btn-primary:hover{color:#fff;background-color:#0c2442;border-color:#0c2442}
.btn-primary.focus,
.btn-primary:focus{box-shadow:0 0 0 .2rem rgba(0,123,255,.5)}
.btn-primary.disabled,
.btn-primary:disabled{color:#0c2442;background-color:transparent}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,.show>
.btn-primary.dropdown-toggle{color:#fff;background-color:#0c2442;border-color:#0c2442}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,.show>
.btn-primary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(0,123,255,.5)}


/*Custom CSS*/
.AppController{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
}

.fullHeight{
 max-height: 100%;
}

.AppBody{
}
.AppBodyController{
  margin-inline: auto; 
  max-width: 1633px; /* applying max-width */
}

.navBar{
background-color: white;

}


.navHolder{
  max-width: 1633px; /* applying max-width */
}

.topNav{z-index: 110;}

.bottomNav{z-index: 10;}

.navContainer{
  
  background: rgb(245,249,255);
background: linear-gradient(112deg, rgba(245,249,255,1) 53%, rgba(222,228,238,1) 100%);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}


 

.navBorder{
  border-bottom: 1px solid #0c2442;
}

.hiddenNav{
  display: none;
}
.shownNav{
  display:inline;
}

nav.navbar {
  max-width: 1633px; /* applying max-width */
  margin: 0 auto; /* center */
  .navbar-collapse {
      justify-content : center; /* center the elements in the block if there is flex */
      -webkit-justify-content : center; /* make it works on some navigators */
  }
}

.navTitleText{
  word-break: keep-all;
   white-space: nowrap; 
    
  
  }

  .navSearch{
    /* max-width: 1000px; /* applying max-width */
    width: 100%;
    margin: 0 auto; /* center */
  }

    
  .zeroP{
    padding: 0%;
    margin: 0%;
  }

  .navIcon{
    font-size: 1.4rem;
    -webkit-text-stroke: 0.3px
  }

.navSearchButton{
  background-color: #11335c;
}



.navSearchDropDown{
 
  border: 1px solid #0c2442;
  margin-right: 1px;
  border-radius: 13px;
  
}

.f5{
  font-weight: 550;
}

.navLinkText{
  color: #0c2442;
  text-decoration: none;
  
}
.navLinkText :hover {
  color: #20497b;
  
}

.navText{
  color: #0c2442;
  
  
}
.navText :hover {
  color: #0c2442;

}



.primaryBG{
  background-color: #0c2442;
}

.mobileHidden{
  
}

.mobilenavContainer{
  display: none;
}

.leftImage{
  flex-grow: calc(1800/360);
}
.rightImage{
  flex-grow: calc(360/360);
}

.featuredMobile{
  height: 150px;
    
}

.cardProduct{
  width: 200px;
  
}

@media screen and (max-width: 900px) {

  .featuredMobile{
    height: 125px;
      
  }

  .mobileHidden{
    display: none;
  }
  .mobilenavContainer{
    margin-top: auto;
    display: block;;  
    background-color: white;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 11;
  }

  .rightImage{
   display: none;
  }

 
}



/*Tooltip Customization*/
 .tooltip .arrow:before {
      border-top-color: #0c2442 !important;
  }
  
  .tooltip .tooltip-inner {
      background-color: #0c2442 ;
  }

  .overFlowControl{
    contain: paint;
   }

   .mobileNavIcon{
    font-size: 1.6em;
    padding: 2%;
   }
   .mobileNavText{
   
    padding: 5%;
   }

   .mobileSearchBar{
    padding-top: 1%;
    padding-bottom: 1%;
   }

   .topP{
    padding-top: 2%;
   }
   
   #root{


  }
  
  .rect-img-container {
    position: relative;
  }
  
  .rect-img-container::after {
    content: "";
    display: block;
    padding-bottom: 100%;
    max-width: 50px;
    max-height: 50px;
  }
  
  .rect-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  
  }

  .App {
    font-family: sans-serif;
    text-align: center;
    padding: 0 !important;
    padding: 3rem 0;
  }
  
  .wrapper {
    text-align: center;
  }
  
  body {
    overflow-x: hidden;
  }
  .slide-item {
    overflow: hidden;
    position: absolute;
    width: 98% !important;
    transition: all 0.3s ease-in-out;
  }
  
  
  
  .alice-carousel__wrapper {
    padding: 50px 0;
    height: 250px !important;
    margin: 0;
  }
  
  .slide-item > img {
    pointer-events: none;
  }
  
  .mobile .slide-item:hover {
    transform: scaleY(1) scalex(1);
    box-shadow: none;
  }
  
  .alice-carousel__wrapper {
    left: 0px;
  }

  .homeCarousel{

    max-width: 100%;
    object-fit: cover;
  }



  .tw-equal-height-images {
    display: flex;
  }
  .tw-equal-height-images img {
    display: block;
    flex-basis: 0px;
    width: 0px;
    height: auto;
  }

  .whiteBG{
    background-color: white;
  }

  .fhpimage{
    object-fit: cover;
  }
  .secondaryBG{
    background-color: #f5f9ff;
  }
 
  .secondaryText{
    color: #f5f9ff;
  }
  .primaryText{
    color: #0c2442
  }

  .blueText{
    color: #146dda
  }

  .cardShadow{
 box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05), 0 6px 20px 0 rgba(0, 0, 0, 0.05); 
  }

  .zeroBorder{
    border: 0;
  }

  .baseBlock {
  


    -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .baseBlock:hover {
    -webkit-transform: translate(0, -8px);
    -moz-transform: translate(0, -8px);
    -ms-transform: translate(0, -8px);
    -o-transform: translate(0, -8px);
    transform: translate(0, -8px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }

.list-group{
      display:inline-block;
 }
